import React from "react";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: theme.palette.success.main,
  },
}));

const SuccessIndicator: React.FunctionComponent = () => {
  const classes = useStyles();
  return <Icon className={classes.successIcon}>check_circle_outline</Icon>;
};

export default SuccessIndicator;

import React from "react";

import { Link } from "@material-ui/core";

import Paragraph from "../atoms/Paragraph";
import { adminSites } from "../../requests";

interface AdminSiteLinkProps {
  link: string;
  siteName: string;
}

const AdminSiteLink: React.FC<AdminSiteLinkProps> = ({
  link,
  siteName,
}: AdminSiteLinkProps) => {
  return (
    <Paragraph>
      You can also use{" "}
      <Link href={link} target="_blank">
        {siteName} Admin Site
      </Link>{" "}
      to manage Users directly
    </Paragraph>
  );
};

export const GoogleAdminSiteLink: React.FC = () => {
  return <AdminSiteLink siteName="Google" link={adminSites.google} />;
};
export const JiraAdminSiteLink: React.FC = () => {
  return <AdminSiteLink siteName="Jira" link={adminSites.jira} />;
};
export const GitlabAdminSiteLink: React.FC = () => {
  return <AdminSiteLink siteName="Gitlab" link={adminSites.gitlab} />;
};
export const BasecampAdminSiteLink: React.FC = () => {
  return <AdminSiteLink siteName="Basecamp" link={adminSites.basecamp} />;
};

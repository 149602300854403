import React from "react";
import { Button } from "@material-ui/core";

interface BackButtonProps {
  onClick: () => void;
  isFirstStep: boolean;
}

const BackButton: React.FunctionComponent<BackButtonProps> = ({
  onClick,
  isFirstStep,
}: BackButtonProps) => {
  return (
    <Button onClick={onClick} disabled={isFirstStep}>
      Back
    </Button>
  );
};

export default BackButton;

import React, { useState } from "react";
import {
  Container,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from "@material-ui/core";

import StepControls from "../molecules/StepControls";
import ResetStepper from "../molecules/ResetStepper";

interface ServiceStepperProps {
  steps: Array<string>;
  stepContents: Array<JSX.Element>;
  onReset?: () => void;
}

const ServiceStepper: React.FunctionComponent<ServiceStepperProps> = ({
  steps,
  stepContents,
  onReset,
}: ServiceStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    if (onReset) {
      onReset();
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {stepContents[index]}
              <StepControls
                stepIndex={activeStep}
                stepsCount={steps.length}
                onBack={handleBack}
                onNext={handleNext}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <ResetStepper onReset={handleReset} />
      )}
    </Container>
  );
};

export default ServiceStepper;

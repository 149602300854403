import React, { useState, useEffect } from "react";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import { getGoogleGroups } from "../../../requests";
import {logout} from "../../../helpers";

interface GoogleGroupSelectInput {
  value: string;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const GoogleGroupSelect: React.FunctionComponent<GoogleGroupSelectInput> = ({
  value,
  onChange,
}: GoogleGroupSelectInput) => {
  const [groups, setGroups] = useState<Array<Record<string, string>>>([]);

  useEffect(() => {
    async function setGroupsFromAPI() {
      try {
        const groupsResp = await getGoogleGroups();
        setGroups(groupsResp.data);
      } catch (error) {
        console.log(error);
        if (error.isAxiosError && error.response.status === 401) {
          logout();
        }
      }
    }
    setGroupsFromAPI();
  }, []);

  return (
    <>
      <InputLabel style={{ display: "inline-block", marginRight: ".6em" }}>
        Add to Google Group for:
      </InputLabel>
      <Select name="addToOfficeGroup" value={value} onChange={onChange}>
        <MenuItem aria-label="None" value="">
          {" "}
        </MenuItem>
        {Object.keys(groups).map((key) => (
          <MenuItem key={key} value={key}>
            {key[0].toUpperCase() + key.slice(1)}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default GoogleGroupSelect;

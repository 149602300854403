import axios, { AxiosResponse } from "axios";

import { UserData, Status, GoogleGroupsRequestInput } from "./interfaces";

async function post(url: string, data: any): Promise<Status | undefined> {
  return await axios.post(url, data);
}

export async function createGoogleUser(
  data: UserData
): Promise<Status | undefined> {
  return await post("/api/google/users", data);
}

export async function sendPasswordEmail(
  data: any
): Promise<Status | undefined> {
  return await post("api/google/send_password", data);
}

export async function addUserToGoogleGroups(
  data: GoogleGroupsRequestInput
): Promise<Status | undefined> {
  return await post("/api/google/add_to_groups", data);
}

export async function createJiraUser(
  data: UserData
): Promise<Status | undefined> {
  return await post("api/jira/users", data);
}

export async function createGitlabUser(
  data: UserData
): Promise<Status | undefined> {
  return await post("api/gitlab/users", data);
}

export async function getMe(): Promise<AxiosResponse<{ email: string }>> {
  return await axios.get("api/me");
}

export async function getGoogleUsers(): Promise<AxiosResponse> {
  return await axios.get("api/google/users");
}

export async function getGoogleGroups(): Promise<AxiosResponse> {
  return await axios.get("api/google/groups");
}

export async function getJiraUser(email: string): Promise<AxiosResponse> {
  return await axios({
    method: "get",
    url: "api/jira/users",
    params: { email: email },
  });
}

export async function getBasecampUser(email: string): Promise<AxiosResponse> {
  return await axios({
    method: "get",
    url: "api/basecamp/users",
    params: { email: email },
  });
}

export async function getGitlabUser(email: string): Promise<AxiosResponse> {
  return await axios({
    method: "get",
    url: "api/gitlab/users",
    params: { email: email },
  });
}

export async function blockGitlabUser(id: number): Promise<AxiosResponse> {
  return await axios({
    method: "delete",
    url: `api/gitlab/users/${id}`,
  });
}

export async function removeGoogleUser(email: string): Promise<AxiosResponse> {
  return await axios({
    method: "delete",
    url: `api/google/users/${email}`,
  });
}

export const loginUrl = "api/google/login";
export const gitlabLoginUrl = "api/gitlab/login";
export const basecampLoginUrl = "api/basecamp/login";
export const adminSites = {
  google: "https://admin.google.com/ac/users",
  jira: "https://admin.atlassian.com/s/217d6894-0091-4031-9190-1ab71a1b0603/users",
  gitlab: "https://code.innocode.dev/admin/users",
  basecamp: "https://3.basecamp.com/3347947/account/people",
  slack: "https://innocode.slack.com/admin",
}

import React from "react";
import { List, ListItem } from "@material-ui/core";

import UserAttribute from "../atoms/UserAttribute";

export enum LabelOptions {
  ADD_USER,
  REMOVE_USER,
}

interface UserDataListProps {
  attributes: Array<{ name: string; value?: string }>;
  label?: LabelOptions;
}

const UserDataList: React.FunctionComponent<UserDataListProps> = ({
  attributes,
  label,
}: UserDataListProps) => {
  const attrList = attributes.map((attr) => (
    <ListItem key={attr.name}>
      <UserAttribute {...attr} />
    </ListItem>
  ));
  const labelText =
    label === LabelOptions.REMOVE_USER
      ? "The following user will be removed/blocked:"
      : "The user will be created with the following data:";
  return (
    <div>
      <p>{labelText}</p>
      <List>{attrList}</List>
    </div>
  );
};

export default UserDataList;

import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  userAttrValue: {
    color: theme.palette.secondary.dark,
    fontWeight: "bold"
  },
}));

interface UserAttributeProps {
  name: string;
  value?: string;
}

const UserAttribute: React.FunctionComponent<UserAttributeProps> = ({
  name,
  value,
}: UserAttributeProps) => {
  const classes = useStyles();
  return (
    <Typography variant="body2">
      {name + ": "}
      <Typography
        variant="body2"
        className={classes.userAttrValue}
        component="span"
      >
        {value}
      </Typography>
    </Typography>
  );
};

export default UserAttribute;

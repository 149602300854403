import React from "react";

import { getJiraUser } from "../../../requests";

import JiraError from "../../atoms/errors/JiraError";
import CheckUserRemoved from "../CheckUserRemoved";

interface JiraDisableUserProps {
  userEmail: string;
  userDisabled: boolean;
  onDisabled: () => void;
}

const JiraDisableUser: React.FC<JiraDisableUserProps> = ({
  userEmail,
  userDisabled,
  onDisabled,
}: JiraDisableUserProps) => {
  const errorComponent = <JiraError />;
  return (
    <CheckUserRemoved
      getUserFunc={getJiraUser}
      errorComponent={errorComponent}
      userEmail={userEmail}
      userDisabled={userDisabled}
      onDisabled={onDisabled}
    />
  );
};

export default JiraDisableUser;

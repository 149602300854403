import React from "react";

import Paragraph from "../Paragraph";

interface RequestErrorProps {
  errorText: string;
}

const RequestError: React.FC<RequestErrorProps> = ({
  errorText,
}: RequestErrorProps) => {
  return (
    <Paragraph>
      An error occurred: <span style={{ color: "red" }}>{errorText}</span>
    </Paragraph>
  );
};

export default RequestError;

import React from "react";
import { FormControl, TextField } from "@material-ui/core";

import useRequest from "../../hooks";
import { UserData, Action, CreateUserActions } from "../../interfaces";
import { createGoogleUser } from "../../requests";
import FormGroup from "../atoms/FormGroup";
import UserNameInput from "../molecules/google/UserNameInput";
import PrimaryEmailInput from "../molecules/google/PrimaryEmailInput";
import LoaderButton from "../atoms/buttons/LoaderButton";
import RequestError from "../atoms/errors/RequestError";

interface GoogleUserInputsProps {
  userData: UserData;
  dispatch: React.Dispatch<Action>;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const GoogleUserInputs: React.FunctionComponent<GoogleUserInputsProps> = ({
  userData,
  dispatch,
  onChange,
}: GoogleUserInputsProps) => {
  const { requestStatus, makeRequest } = useRequest(
    createGoogleUser,
    userData,
    (response) =>
      dispatch({
        type: CreateUserActions.GOOGLE_USER_CREATED,
        payload: { password: response.data.password, link: response.data.link },
      })
  );
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    makeRequest();
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <UserNameInput
          firstName={userData.firstName}
          surname={userData.surname}
          onChange={onChange}
        />
      </FormGroup>
      <FormGroup>
        <PrimaryEmailInput
          onChange={onChange}
          emailLocal={userData.primaryEmailLocal}
        />
      </FormGroup>
      <FormGroup>
        <FormControl>
          <TextField
            label="Secondary Email"
            name="secondaryEmail"
            value={userData.secondaryEmail}
            onChange={onChange}
          ></TextField>
        </FormControl>
      </FormGroup>
      {requestStatus.error && <RequestError errorText={requestStatus.error} />}
      <LoaderButton
        type="submit"
        variant="contained"
        color="secondary"
        isLoading={requestStatus.loading}
      >
        Create User
      </LoaderButton>
    </form>
  );
};

export default GoogleUserInputs;

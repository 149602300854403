import React from "react";

import StepSection from "../atoms/StepSection";
import HeaderWithIndicator from "../molecules/HeaderWithIndicator";

import {
  UserData,
  GoogleStepState,
  Action,
  CreateUserActions,
} from "../../interfaces";
import { handleChangeFactory } from "../../helpers";

import UserInputs from "./GoogleUserInputs";
import GoogleGroupsInput from "./GoogleGroupsInput";
import PasswordOutput from "../molecules/google/PasswordOutput";
import LinkToCreatedAccount from "../atoms/LinkToCreatedAccount";
import { GoogleAdminSiteLink } from "../molecules/AdminSiteLink";

interface GoogleProps {
  userData: UserData;
  dispatch: React.Dispatch<Action>;
  stepState: GoogleStepState;
}

const CreateGoogleUser: React.FunctionComponent<GoogleProps> = ({
  userData,
  dispatch,
  stepState,
}: GoogleProps) => {
  const handleUserChange = handleChangeFactory(
    dispatch,
    CreateUserActions.SET_USER_DATA
  );

  const handleGroupsChange = handleChangeFactory(
    dispatch,
    CreateUserActions.SET_GOOGLE_GROUP_OPTIONS
  );

  return (
    <section>
      <StepSection>
        <HeaderWithIndicator isSuccess={stepState.userCreated}>
          Create User
        </HeaderWithIndicator>
        <GoogleAdminSiteLink />
        <UserInputs
          userData={userData}
          dispatch={dispatch}
          onChange={handleUserChange}
        />
        {stepState.link && <LinkToCreatedAccount link={stepState.link} />}
        {stepState.userPassword && (
          <PasswordOutput
            password={stepState.userPassword || ""}
            userData={userData}
          />
        )}
      </StepSection>
      <StepSection>
        <HeaderWithIndicator isSuccess={stepState.userAddedToGroups}>
          Add User to Office Groups
        </HeaderWithIndicator>
        <GoogleGroupsInput
          userData={userData}
          onChange={handleGroupsChange}
          dispatch={dispatch}
          groupsOptions={stepState.groupOptions}
        />
      </StepSection>
    </section>
  );
};

export default CreateGoogleUser;

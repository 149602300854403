import React from "react";
import { Button } from "@material-ui/core";

import StepSection from "../atoms/StepSection";
import Paragraph from "../atoms/Paragraph";

interface ResetStepperProps {
  onReset: () => void;
}

const ResetStepper: React.FunctionComponent<ResetStepperProps> = ({
  onReset,
}: ResetStepperProps) => {
  return (
    <StepSection>
      <Paragraph>All steps completed - you&apos;re finished</Paragraph>
      <Button variant="contained" color="primary" onClick={onReset}>
        Reset
      </Button>
    </StepSection>
  );
};

export default ResetStepper;

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

import { SiteActions } from "../../interfaces";
import { logout } from "../../helpers";

interface HeaderProps {
  currentUserEmail: string;
  selectedAction?: SiteActions;
  onSelectAction: (action: SiteActions) => void;
}

const useStyles = makeStyles({
  menuOption: {
    marginLeft: "10px",
    color: "white",
  },
});

const Header: React.FC<HeaderProps> = ({
  currentUserEmail,
  selectedAction,
  onSelectAction,
}: HeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const classes = useStyles();
  return (
    <AppBar position="sticky">
      <Toolbar>
        <div>
          <Button
            className={classes.menuOption}
            disabled={selectedAction === SiteActions.ADD_USER}
            onClick={() => onSelectAction(SiteActions.ADD_USER)}
          >
            Go To Add User
          </Button>
          <Button
            className={classes.menuOption}
            disabled={selectedAction === SiteActions.REMOVE_USER}
            onClick={() => onSelectAction(SiteActions.REMOVE_USER)}
          >
            Go To Remove User
          </Button>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Tooltip title={currentUserEmail}>
              <AccountCircle />
            </Tooltip>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

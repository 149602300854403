import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  formGroup: {
    "margin-bottom": "1em",
  },
});

interface FormGroupProps {
  children: React.ReactNode;
}

const FormGroup: React.FunctionComponent<FormGroupProps> = ({
  children,
}: FormGroupProps) => {
  const classes = useStyles();
  return <div className={classes.formGroup}>{children}</div>;
};

export default FormGroup;

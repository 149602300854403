import React from "react";
import { CssBaseline } from "@material-ui/core";

import HomePage from "./pages/HomePage";

import "./index.css";

function App(): JSX.Element {
  return (
    <>
      <CssBaseline />
      <HomePage />
    </>
  );
}

export default App;

import React from "react";

import StepSection from "../../atoms/StepSection";
import HeaderWithIndicator from "../HeaderWithIndicator";

const JiraAuthorization: React.FunctionComponent = () => {
  return (
    <StepSection>
      <HeaderWithIndicator isSuccess>Authorize</HeaderWithIndicator>
      <p>
        You don&apos;t need to authorize to Jira/Confluence here, but bare in
        mind that this application makes all actions from Olesia Grydhzuk&apos;s
        account.
      </p>
    </StepSection>
  );
};

export default JiraAuthorization;

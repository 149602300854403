import React from "react";

import { Button, ButtonProps } from "@material-ui/core";

interface LoaderButtonProps extends ButtonProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const LoaderButton: React.FC<LoaderButtonProps> = ({
  isLoading,
  children,
  ...props
}: LoaderButtonProps) => {
  const content = isLoading ? "Loading..." : children;
  const disabled = props.disabled || isLoading;
  return (
    <Button {...props} disabled={disabled}>
      {content}
    </Button>
  );
};

export default LoaderButton;

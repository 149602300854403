import React from "react";

import { Link } from "@material-ui/core";

import Paragraph from "../Paragraph";
import { adminSites } from "../../../requests";

const JiraError: React.FC = () => {
  return (
    <Paragraph>
      Something went wrong. Please use{" "}
      <Link href={adminSites.jira} target="_blank">
        Atlassian Admin page
      </Link>{" "}
      directly.
    </Paragraph>
  );
};

export default JiraError;

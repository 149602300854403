import React from "react";

import useRequest from "../../hooks";
import {
  Action,
  CreateUserActions,
  UserData,
  CreateUserStepState,
} from "../../interfaces";
import { createJiraUser } from "../../requests";

import JiraAuthorization from "../molecules/jira/JiraAuthorization";
import JiraUserData from "../molecules/jira/JiraUserData";
import HeaderWithIndicator from "../molecules/HeaderWithIndicator";
import LoaderButton from "../atoms/buttons/LoaderButton";
import StepSection from "../atoms/StepSection";
import LinkToCreatedAccount from "../atoms/LinkToCreatedAccount";
import RequestError from "../atoms/errors/RequestError";
import {JiraAdminSiteLink} from "../molecules/AdminSiteLink";

interface CreateJiraUserProps {
  userData: UserData;
  dispatch: React.Dispatch<Action>;
  stepState: CreateUserStepState;
}

const CreateJiraUser: React.FunctionComponent<CreateJiraUserProps> = ({
  userData,
  dispatch,
  stepState,
}: CreateJiraUserProps) => {
  const { requestStatus, makeRequest } = useRequest(
    createJiraUser,
    userData,
    (response) =>
      dispatch({
        type: CreateUserActions.JIRA_USER_CREATED,
        payload: { link: response.data.link },
      })
  );
  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    makeRequest();
  };
  return (
    <section>
      <JiraAdminSiteLink />
      <JiraAuthorization />
      <StepSection>
        <HeaderWithIndicator isSuccess={stepState.userCreated}>
          Create User
        </HeaderWithIndicator>
        <JiraUserData userData={userData} />
        {requestStatus.error && (
          <RequestError errorText={requestStatus.error} />
        )}
        {stepState.link && <LinkToCreatedAccount link={stepState.link} />}
        <LoaderButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          isLoading={requestStatus.loading}
        >
          Create User
        </LoaderButton>
      </StepSection>
    </section>
  );
};

export default CreateJiraUser;

import React from "react";

import { UserData } from "../../../interfaces";

import UserDataList from "../UserDataList";

interface GitlabUserDataProps {
  userData: UserData;
}

const GitlabUserData: React.FunctionComponent<GitlabUserDataProps> = ({
  userData,
}: GitlabUserDataProps) => {
  const firstName: string = userData.firstName || "";
  const surname: string = userData.surname || "";
  const jiraUserData = [
    { name: "Email address", value: userData.primaryEmail },
    {
      name: "Name",
      value: `${firstName} ${surname}`,
    },
    {
      name: "Username",
      value: `${firstName.toLowerCase()}.${surname.toLowerCase()}`,
    },
  ];
  return <UserDataList attributes={jiraUserData} />;
};

export default GitlabUserData;

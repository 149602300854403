import React from "react";
import {
  Action,
  RemoveUserActions,
  RemoveUserStepState,
  UserToRemove,
} from "../../interfaces";
import Paragraph from "../atoms/Paragraph";

import JiraDisableUser from "../molecules/jira/JiraDisableUser";
import { JiraAdminSiteLink } from "../molecules/AdminSiteLink";

interface RemoveJiraUserProps {
  userData: UserToRemove;
  dispatch: React.Dispatch<Action>;
  stepState: RemoveUserStepState;
}

const RemoveJiraUser: React.FC<RemoveJiraUserProps> = ({
  userData,
  dispatch,
  stepState,
}: RemoveJiraUserProps) => {
  const { userRemoved } = stepState;
  const handleUserDisabled = () =>
    dispatch({ type: RemoveUserActions.JIRA_USER_DISABLED });
  return (
    <section>
      <JiraAdminSiteLink />
      {userData.email ? (
        <JiraDisableUser
          userEmail={userData.email}
          userDisabled={userRemoved}
          onDisabled={handleUserDisabled}
        />
      ) : (
        <Paragraph>No user selected.</Paragraph>
      )}
    </section>
  );
};

export default RemoveJiraUser;

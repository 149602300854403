import React, { useReducer } from "react";
import { merge } from "lodash-es";

import { CreateUserPageState, CreateUserActions, Action } from "../interfaces";
import ServiceStepper from "../components/organisms/ServiceStepper";
import CreateGoogleUsers from "../components/organisms/CreateGoogleUser";
import CreateGitlabUser from "../components/organisms/CreateGitLabUser";
import CreateJiraUser from "../components/organisms/CreateJiraUser";
import CreateUserOtherServices from "../components/organisms/CreateUserOtherServices";

function getInitialState(): CreateUserPageState {
  return {
    userData: {
      firstName: "",
      surname: "",
      primaryEmailLocal: "",
      primaryEmail: "",
      secondaryEmail: "",
    },
    google: {
      authorized: true,
      userPassword: undefined,
      groupOptions: {
        addToOfficeGroup: "lviv",
      },
      userCreated: false,
      userAddedToGroups: false,
      link: undefined,
    },
    jira: {
      authorized: true,
      userCreated: false,
      link: undefined,
    },
    gitlab: {
      authorized: false,
      userCreated: false,
      link: undefined,
    },
  };
}

function reducer(
  state: CreateUserPageState,
  action: Action
): CreateUserPageState {
  let newState;
  switch (action.type) {
    case CreateUserActions.SET_USER_DATA: {
      if (action.payload && "primaryEmailLocal" in action.payload) {
        action.payload["primaryEmail"] =
          action.payload["primaryEmailLocal"] + "@innocode.no";
      }
      newState = { userData: action.payload };
      break;
    }
    case CreateUserActions.SET_GOOGLE_GROUP_OPTIONS: {
      newState = { google: { groupOptions: action.payload } };
      break;
    }
    case CreateUserActions.GOOGLE_USER_CREATED: {
      newState = {
        google: {
          userCreated: true,
          userPassword: action.payload.password,
          link: action.payload.link,
        },
      };
      break;
    }
    case CreateUserActions.GOOGLE_USER_ADDED_TO_GROUPS: {
      newState = { google: { userAddedToGroups: true } };
      break;
    }
    case CreateUserActions.JIRA_USER_CREATED:
      newState = { jira: { userCreated: true, link: action.payload.link } };
      break;
    case CreateUserActions.GITLAB_AUTHORIZED:
      newState = { gitlab: { authorized: true } };
      break;
    case CreateUserActions.GITLAB_USER_CREATED: {
      newState = { gitlab: { userCreated: true, link: action.payload.link } };
      break;
    }
    case CreateUserActions.RESET_USER: {
      const resetState = getInitialState();
      resetState.gitlab.authorized = state.gitlab.authorized;
      return resetState;
    }
    default:
      throw Error("Unknown action");
  }
  const updatedState: CreateUserPageState = merge(state, newState);
  return { ...updatedState };
}

export default function CreateUserPage(): JSX.Element {
  const [pageState, dispatch] = useReducer(reducer, getInitialState());

  const steps = ["Google", "Jira/Confluence", "Gitlab", "Other Services"];

  const stepContents = [
    <CreateGoogleUsers
      key={"google"}
      userData={pageState.userData}
      stepState={pageState.google}
      dispatch={dispatch}
    />,
    <CreateJiraUser
      key={"jira"}
      userData={pageState.userData}
      dispatch={dispatch}
      stepState={pageState.jira}
    />,
    <CreateGitlabUser
      key={"gitlab"}
      userData={pageState.userData}
      dispatch={dispatch}
      stepState={pageState.gitlab}
    />,
    <CreateUserOtherServices key="other_service" />,
  ];

  return (
    <ServiceStepper
      steps={steps}
      stepContents={stepContents}
      onReset={() => dispatch({ type: CreateUserActions.RESET_USER })}
    />
  );
}

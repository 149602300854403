import React from "react";

import { getBasecampUser } from "../../../requests";

import BasecampError from "../../atoms/errors/BasecampError";
import CheckUserRemoved from "../CheckUserRemoved";

interface BasecampUserRemovedProps {
  userEmail: string;
  userDisabled: boolean;
  onDisabled: () => void;
}

const BasecampUserRemoved: React.FC<BasecampUserRemovedProps> = ({
  userEmail,
  userDisabled,
  onDisabled,
}: BasecampUserRemovedProps) => {
  const errorComponent = <BasecampError />;
  return (
    <CheckUserRemoved
      getUserFunc={getBasecampUser}
      errorComponent={errorComponent}
      userEmail={userEmail}
      userDisabled={userDisabled}
      onDisabled={onDisabled}
    />
  );
};

export default BasecampUserRemoved;

import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { UserToRemove } from "../../../interfaces";
import { getGoogleUsers } from "../../../requests";
import { logout } from "../../../helpers";

interface GoogleUserSelectProps {
  userToRemove: UserToRemove;
  setUserToRemove: (user: UserToRemove) => void;
}

const GoogleUserSelect: React.FC<GoogleUserSelectProps> = ({
  userToRemove,
  setUserToRemove,
}: GoogleUserSelectProps) => {
  const [users, setUsers] = useState<any>([]);
  useEffect(() => {
    async function setGoogleUsers() {
      try {
        const usersResp = await getGoogleUsers();
        setUsers(
          usersResp.data.users.map((user: any) => ({
            email: user.primaryEmail,
            fullName: user.name.fullName,
            googleId: user.id,
          }))
        );
      } catch (error) {
        console.log(error);
        if (error.isAxiosError && error.response.status === 401) {
          logout();
        }
      }
    }
    setGoogleUsers();
  }, []);
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={users}
      style={{ width: 300 }}
      getOptionLabel={(option: UserToRemove) => option.fullName}
      getOptionSelected={(option: UserToRemove, value: UserToRemove) =>
        option.email === value.email
      }
      value={userToRemove}
      onChange={(event: any, newValue: UserToRemove | null) => {
        if (newValue) {
          setUserToRemove(newValue);
        }
      }}
      renderInput={(params) => <TextField {...params} label="User" />}
    />
  );
};

export default GoogleUserSelect;

import React from "react";
import { Grid, TextField, FormControl, FormLabel } from "@material-ui/core";

interface PrimaryEmailInputProps {
  emailLocal?: string;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const PrimaryEmailInput: React.FunctionComponent<PrimaryEmailInputProps> = ({
  emailLocal,
  onChange,
}: PrimaryEmailInputProps) => {
  return (
    <Grid container alignItems="flex-end">
      <Grid item>
        <FormControl>
          <TextField
            label="Primary Email"
            name="primaryEmailLocal"
            value={emailLocal}
            onChange={onChange}
          ></TextField>
        </FormControl>
      </Grid>
      <Grid item>
        <FormLabel>@innocode.no</FormLabel>
      </Grid>
    </Grid>
  );
};

export default PrimaryEmailInput;

import React from "react";

import { Link, List, ListItem } from "@material-ui/core";

import Paragraph from "../atoms/Paragraph";
import StepSection from "../atoms/StepSection";
import { adminSites } from "../../requests";

const RemoveUserOtherServices: React.FC = () => {
  return (
    <section>
      <StepSection>
        <Paragraph>
          Don&apos;t forget to remove user from other services:
        </Paragraph>
        <List>
          <ListItem>
            <Link href={adminSites.slack} target="_blank">
              Slack
            </Link>
          </ListItem>
        </List>
      </StepSection>
    </section>
  );
};

export default RemoveUserOtherServices;

import React from "react";

import {
  Action,
  RemoveUserActions,
  RemoveUserStepState,
  UserToRemove,
} from "../../interfaces";
import Paragraph from "../atoms/Paragraph";

import BasecampAuthorization from "../molecules/basecamp/BasecampAuthorize";
import BasecampRemoveUser from "../molecules/basecamp/BasecampRemoveUser";
import { BasecampAdminSiteLink } from "../molecules/AdminSiteLink";

interface RemoveBasecampUserProps {
  userData: UserToRemove;
  stepState: RemoveUserStepState;
  dispatch: React.Dispatch<Action>;
}

const RemoveBasecampUser: React.FC<RemoveBasecampUserProps> = ({
  userData,
  stepState,
  dispatch,
}: RemoveBasecampUserProps) => {
  const { authorized, userRemoved } = stepState;
  const handleUserDisabled = () =>
    dispatch({
      type: RemoveUserActions.BASECAMP_USER_REMOVED,
    });
  return (
    <section>
      <BasecampAdminSiteLink />
      {userData.email ? (
        <>
          <BasecampAuthorization
            isAuthorized={authorized}
            onAuthorize={() =>
              dispatch({ type: RemoveUserActions.BASECAMP_AUTHORIZED })
            }
          />
          {authorized && (
            <BasecampRemoveUser
              userEmail={userData.email}
              userDisabled={userRemoved}
              onDisabled={handleUserDisabled}
            />
          )}
        </>
      ) : (
        <Paragraph>No user selected.</Paragraph>
      )}
    </section>
  );
};

export default RemoveBasecampUser;

import React from "react";

import { UserData } from "../../../interfaces";

import UserDataList from "../UserDataList";

interface JiraUserDataProps {
  userData: UserData;
}

const JiraUserData: React.FunctionComponent<JiraUserDataProps> = ({
  userData,
}: JiraUserDataProps) => {
  const jiraUserData = [
    {name: "Email address", value: userData.primaryEmail },
    {name: "Display name", value: `${userData.firstName} ${userData.surname}`},
  ];
  return <UserDataList attributes={jiraUserData} />;
};

export default JiraUserData;

import {Action, Actions } from "./interfaces";

export function handleChangeFactory(
  dispatch: React.Dispatch<Action>,
  actionType: Actions
): (event: React.ChangeEvent<any>) => void {
  return (event: React.ChangeEvent<any>): void => {
    event.persist();
    dispatch({
      type: actionType,
      payload: {
        [event.target.name]:
          event.target.type === "checkbox"
            ? Boolean(event.target.checked)
            : event.target.value,
      },
    });
  };
}


export function logout(): void {
  window.location.href = "api/logout";
}

import React, { useCallback, useEffect } from "react";
import { Button } from "@material-ui/core";

import { gitlabLoginUrl } from "../../../requests";

import StepSection from "../../atoms/StepSection";
import HeaderWithIndicator from "../HeaderWithIndicator";
import Paragraph from "../../atoms/Paragraph";

interface GitlabAuthorizationProps {
  isAuthorized: boolean;
  onAuthorize: () => void;
}

const AuthorizeText: React.FC = () => {
  const openPopup = () => {
    window.open(gitlabLoginUrl, "", "width=1000,height=700");
  };
  return (
    <>
      <Paragraph>You need to authorize to manage users in Gitlab</Paragraph>
      <Button variant="contained" color="secondary" onClick={openPopup}>
        Authorize
      </Button>
    </>
  );
};

const AuthorizedText: React.FC = () => {
  return <Paragraph>You&apos;ve successfully authorized</Paragraph>;
};

const GitlabAuthorization: React.FC<GitlabAuthorizationProps> = ({
  isAuthorized,
  onAuthorize,
}: GitlabAuthorizationProps) => {
  const handleMessage = useCallback((event) => {
    if (event.data == "gitlab_authorized") {
      onAuthorize();
    }
  }, []);
  useEffect(() => {
    if (!isAuthorized) {
      window.addEventListener("message", handleMessage);
    }
    return () => window.removeEventListener("message", handleMessage);
  }, []);
  return (
    <StepSection>
      <HeaderWithIndicator isSuccess={isAuthorized}>
        Authorize
      </HeaderWithIndicator>
      {isAuthorized ? <AuthorizedText /> : <AuthorizeText />}
    </StepSection>
  );
};

export default GitlabAuthorization;

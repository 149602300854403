import { AxiosResponse } from "axios";
import { useState } from "react";

interface RequestStatus {
  loading: boolean;
  error: string | null;
  result: any;
}

function useRequest<T>(
  requestFunction: (data: T) => Promise<any>,
  data: T,
  dispatchSuccess?: (response: AxiosResponse) => void
): { requestStatus: RequestStatus; makeRequest: () => Promise<any> } {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<any>(null);

  async function makeRequest() {
    setError(null);
    setLoading(true);
    try {
      const response = await requestFunction(data);
      setResult(response);
      if (dispatchSuccess) {
        dispatchSuccess(response);
      }
    } catch (error) {
      if (error.response) {
        setError(JSON.stringify(error.response.data));
      } else {
        setError(error.message);
      }
    }
    setLoading(false);
  }
  return {
    requestStatus: { loading: loading, error: error, result: result },
    makeRequest: makeRequest,
  };
}

export default useRequest;

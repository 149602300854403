import React from "react";
import { Container } from "@material-ui/core";

import NextButton from "../atoms/buttons/NextButton";
import BackButton from "../atoms/buttons/BackButton";

interface StepControlsProps {
  stepIndex: number;
  stepsCount: number;
  onNext: () => void;
  onBack: () => void;
}

const StepControls: React.FunctionComponent<StepControlsProps> = ({
  stepIndex,
  stepsCount,
  onBack,
  onNext,
}: StepControlsProps) => {
  return (
    <Container disableGutters>
      <BackButton isFirstStep={stepIndex === 0} onClick={onBack} />
      <NextButton isLastStep={stepIndex === stepsCount - 1} onClick={onNext} />
    </Container>
  );
};

export default StepControls;

import React from "react";

import { Link } from "@material-ui/core";
import { UserToRemove } from "../../../interfaces";
import { removeGoogleUser } from "../../../requests";
import useRequest from "../../../hooks";

import HeaderWithIndicator from "../HeaderWithIndicator";
import LoaderButton from "../../atoms/buttons/LoaderButton";
import Paragraph from "../../atoms/Paragraph";
import RequestError from "../../atoms/errors/RequestError";

interface GoogleRemoveUserProps {
  userData: UserToRemove;
  userRemoved: boolean;
  onRemove: () => void;
}

const GoogleRemoveUser: React.FC<GoogleRemoveUserProps> = ({
  userData,
  userRemoved,
  onRemove,
}: GoogleRemoveUserProps) => {
  const { requestStatus, makeRequest } = useRequest(
    removeGoogleUser,
    userData && userData.googleId,
    onRemove
  );
  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    makeRequest();
    event.preventDefault();
  };
  // TODO move to api
  const userLink = `https://admin.google.com/ac/users/${userData.googleId}`;
  return (
    <>
      <HeaderWithIndicator isSuccess={userRemoved}>
        Remove User
      </HeaderWithIndicator>
      <Paragraph>
        You are about to remove user{" "}
        <Link href={userLink} target="_blank">
          {userData.fullName}
        </Link>{" "}
        ({userData.email}). The ownership of their documents will be transferred
        to <i>Valerii Shypunov&apos;s</i> account. Proceed?
      </Paragraph>
      <LoaderButton
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={userRemoved}
        isLoading={requestStatus.loading}
      >
        Remove User
      </LoaderButton>
      {requestStatus.error && <RequestError errorText={requestStatus.error} />}
    </>
  );
};

export default GoogleRemoveUser;

import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

interface SectionHeaderProps {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  sectionHeader: {
    marginTop: "2em",
    marginBottom: "1em",
  },
});

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({
  children,
}: SectionHeaderProps) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.sectionHeader}>
      {children}
    </Typography>
  );
};

export default SectionHeader;

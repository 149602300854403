import React from "react";

import { List, ListItem, Link } from "@material-ui/core";

import Paragraph from "../atoms/Paragraph";
import { adminSites } from "../../requests";

export default function OtherServices(): JSX.Element {
  return (
    <>
      <Paragraph>Don&apos;t forget to add user to other services:</Paragraph>
      <List>
        <ListItem>
          <Link href={adminSites.slack} target="_blank">
            Slack
          </Link>
        </ListItem>
      </List>
      <Paragraph>Share links about two factor authentication in:</Paragraph>
      <List>
        <ListItem>
          {"Google:  "}
          <Link
            href="https://support.google.com/accounts/answer/185839?co=GENIE.Platform%3DDesktop&hl=en#"
            target="_blank"
          >
            link
          </Link>
        </ListItem>
        <ListItem>
          {"Slack: "}
          <Link
            href="https://innocode.slack.com/files/T02AMU6L5/FQCUVU5C4?origin_team=T02AMU6L5"
            target="_blank"
          >
            1
          </Link>
          ,{" "}
          <Link
            href="https://innocode.slack.com/account/settings#two_factor"
            target="_blank"
          >
            2
          </Link>
        </ListItem>
      </List>
    </>
  );
}

import React from "react";

import { Grid, Button, makeStyles, CircularProgress } from "@material-ui/core";
import { ReactComponent as GoogleLogo } from "../assets/icons/google.svg";

const useStyles = makeStyles({
  loginContainer: {
    height: "100%",
  },
  googleLogo: {
    height: "20px",
    width: "20px",
  },
});

interface LoginPageProps {
  isLoading: boolean;
  onLogin: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({
  isLoading,
  onLogin,
}: LoginPageProps) => {
  const classes = useStyles();
  console.log(isLoading);
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.loginContainer}
    >
      <Grid item>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<GoogleLogo className={classes.googleLogo} />}
            onClick={onLogin}
          >
            Log in with Google
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default LoginPage;

export interface UserData {
  firstName?: string;
  surname?: string;
  primaryEmailLocal?: string;
  primaryEmail?: string;
  secondaryEmail?: string;
}

export interface Status {
  status: string;
}

export interface GoogleGroupsOptions {
  addToOfficeGroup: string;
}

export interface GoogleGroupsRequestInput extends GoogleGroupsOptions {
  email?: string;
}

export interface CreateUserStepState {
  authorized: boolean;
  userCreated: boolean;
  link?: string;
}

export interface RemoveUserStepState {
  authorized: boolean;
  userRemoved: boolean;
}

export interface GoogleStepState extends CreateUserStepState {
  userPassword?: string;
  userAddedToGroups: boolean;
  groupOptions: GoogleGroupsOptions;
}

export interface CreateUserPageState {
  userData: UserData;
  google: GoogleStepState;
  jira: CreateUserStepState;
  gitlab: CreateUserStepState;
}

export interface RemoveUserPageState {
  userToRemove: UserToRemove;
  google: RemoveUserStepState;
  jira: RemoveUserStepState;
  gitlab: RemoveUserStepState;
  basecamp: RemoveUserStepState;
}

export enum CreateUserActions {
  SET_USER_DATA,
  SET_GOOGLE_GROUP_OPTIONS,
  GOOGLE_USER_CREATED,
  GOOGLE_USER_ADDED_TO_GROUPS,
  JIRA_USER_CREATED,
  GITLAB_USER_CREATED,
  GITLAB_AUTHORIZED,
  RESET_USER,
}

export enum RemoveUserActions {
  SET_USER_TO_REMOVE,
  GOOGLE_USER_REMOVED,
  JIRA_USER_DISABLED,
  GITLAB_AUTHORIZED,
  GITLAB_USER_BLOCKED,
  BASECAMP_AUTHORIZED,
  BASECAMP_USER_REMOVED,
  RESET_USER,
}

export type Actions = CreateUserActions | RemoveUserActions;

export interface Action {
  type: Actions;
  payload?: any;
}

export interface UserToRemove {
  email: string;
  fullName: string;
  googleId: string;
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export enum SiteActions {
  ADD_USER,
  REMOVE_USER,
}

import React, { useState, useRef } from "react";
import { Icon, Button, makeStyles } from "@material-ui/core";

import StepSection from "../../atoms/StepSection";
import Paragraph from "../../atoms/Paragraph";
import { sendPasswordEmail } from "../../../requests";
import { UserData } from "../../../interfaces";
import useRequest from "../../../hooks";
import LoaderButton from "../../atoms/buttons/LoaderButton";
import RequestError from "../../atoms/errors/RequestError";
import HeaderWithIndicator from "../HeaderWithIndicator";

interface PasswordOutputProps {
  password: string;
  userData: UserData;
}

const useStyles = makeStyles(() => ({
  inlineBlock: {
    display: "inline-block",
  },
  offscreen: {
    position: "absolute",
    left: "-999em",
  },
  copyPasswordBtn: {
    paddingLeft: 0,
  },
}));

const PasswordOutput: React.FC<PasswordOutputProps> = ({
  password,
  userData,
}: PasswordOutputProps) => {
  const [passwordHidden, setPasswordHidden] = useState<boolean>(true);
  const [passwordShared, setPasswordShared] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const hiddenPassword = Array(password.length).fill("●").join("");

  const handlePasswordToggle = () => setPasswordHidden((hidden) => !hidden);
  const handlePasswordCopy = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy");
    }
  };
  const { requestStatus, makeRequest } = useRequest(
    sendPasswordEmail,
    {
      password: password,
      ...userData,
    },
    () => setPasswordShared(true)
  );
  const classes = useStyles();
  return (
    <StepSection>
      <HeaderWithIndicator isSuccess={passwordShared}>
       Share Password
      </HeaderWithIndicator>
      <Paragraph>
        The new user can log in with the following password:
      </Paragraph>
      <div className={classes.inlineBlock}>
        {passwordHidden ? hiddenPassword : password}
      </div>
      <form className={classes.offscreen} aria-hidden="true">
        <textarea ref={textAreaRef} value={password} readOnly />
      </form>
      <div className={classes.inlineBlock}>
        {" "}
        <Button onClick={handlePasswordToggle}>
          <Icon>{passwordHidden ? "visibility" : "visibility_off"}</Icon>
        </Button>
      </div>
      <div></div>
      <div>
        {requestStatus.error && (
          <RequestError errorText={requestStatus.error} />
        )}
        <Button
          className={classes.copyPasswordBtn}
          color="primary"
          onClick={handlePasswordCopy}
        >
          Copy Password
        </Button>
        <LoaderButton
          isLoading={requestStatus.loading}
          color="primary"
          onClick={makeRequest}
        >
          Send Email
        </LoaderButton>
      </div>
    </StepSection>
  );
};

export default PasswordOutput;

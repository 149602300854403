import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  stepSection: {
    "margin-bottom": "3em",
  },
});

interface StepSectionProps {
  children: React.ReactNode;
}

const FormGroup: React.FunctionComponent<StepSectionProps> = ({
  children,
}: StepSectionProps) => {
  const classes = useStyles();
  return <div className={classes.stepSection}>{children}</div>;
};

export default FormGroup;

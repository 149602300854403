import React, { useState, useEffect } from "react";
import { Link } from "@material-ui/core";

import { getGitlabUser, blockGitlabUser } from "../../../requests";
import useRequest from "../../../hooks";

import StepSection from "../../atoms/StepSection";
import HeaderWithIndicator from "../HeaderWithIndicator";
import LoaderButton from "../../atoms/buttons/LoaderButton";
import UserDataList, { LabelOptions } from "../UserDataList";
import Paragraph from "../../atoms/Paragraph";
import GitlabError from "../../atoms/errors/GitlabError";

interface GitlabBlockUserProps {
  userEmail: string;
  userBlocked: boolean;
  onBlock: () => void;
}

const GitlabBlockUser: React.FC<GitlabBlockUserProps> = ({
  userEmail,
  userBlocked,
  onBlock,
}: GitlabBlockUserProps) => {
  const [userData, setUserData] = useState<any>();
  const { requestStatus, makeRequest } = useRequest(
    blockGitlabUser,
    userData && userData.id,
    onBlock
  );
  const [userNotFound, setUserNotFound] = useState<boolean>(false);

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    makeRequest();
    event.preventDefault();
  };
  useEffect(() => {
    async function setUserDataFromAPI() {
      if (!userEmail) return;
      try {
        const resp = await getGitlabUser(userEmail);
        setUserData(resp.data);
        if (resp.data.state === "blocked") {
          onBlock();
        }
      } catch (error) {
        if (error.isAxiosError && error.response.status === 404) {
          setUserNotFound(true);
        } else {
          console.log(error);
        }
      }
    }
    setUserDataFromAPI();
  }, [userEmail, userBlocked]);
  let userAttrs: any = [];
  if (userData) {
    userAttrs = ["Name", "State", "Email"].map((attr) => ({
      name: attr,
      value: userData[attr.toLowerCase()],
    }));
    userAttrs.push({
      name: "Link",
      value: (
        <Link href={userData.web_url} target="_blank">
          {userData.web_url}
        </Link>
      ),
    });
  }
  let content;
  if (userNotFound) {
    content = <Paragraph>User not found.</Paragraph>;
  } else if (userData) {
    content = (
      <>
        <UserDataList attributes={userAttrs} label={LabelOptions.REMOVE_USER} />
        <LoaderButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={userBlocked}
          isLoading={requestStatus.loading}
        >
          Block User
        </LoaderButton>
      </>
    );
  } else {
    content = <GitlabError />;
  }
  return (
    <StepSection>
      <HeaderWithIndicator isSuccess={userBlocked}>
        Block User
      </HeaderWithIndicator>
      {content}
    </StepSection>
  );
};

export default GitlabBlockUser;

import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  paragraph: { marginTop: "1em", marginBottom: "1em" },
});

interface ParagraphPros {
  children: React.ReactNode;
}

const Paragraph: React.FC<ParagraphPros> = ({ children }: ParagraphPros) => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.paragraph}>
      {children}
    </Typography>
  );
};

export default Paragraph;

import React from "react";

import {
  Action,
  RemoveUserActions,
  RemoveUserStepState,
  UserToRemove,
} from "../../interfaces";
import Paragraph from "../atoms/Paragraph";

import GitlabAuthorization from "../molecules/gitlab/GitlabAuthorize";
import GitlabBlockUser from "../molecules/gitlab/GitlabBlockUser";
import { GitlabAdminSiteLink } from "../molecules/AdminSiteLink";

interface RemoveGitlabUserProps {
  userData: UserToRemove;
  stepState: RemoveUserStepState;
  dispatch: React.Dispatch<Action>;
}

const RemoveGitlabUser: React.FC<RemoveGitlabUserProps> = ({
  userData,
  stepState,
  dispatch,
}: RemoveGitlabUserProps) => {
  const { authorized, userRemoved } = stepState;
  return (
    <section>
      <GitlabAdminSiteLink />
      {userData.email ? (
        <>
          <GitlabAuthorization
            isAuthorized={authorized}
            onAuthorize={() =>
              dispatch({ type: RemoveUserActions.GITLAB_AUTHORIZED })
            }
          />
          {authorized && (
            <GitlabBlockUser
              userBlocked={userRemoved}
              userEmail={userData.email}
              onBlock={() =>
                dispatch({ type: RemoveUserActions.GITLAB_USER_BLOCKED })
              }
            />
          )}
        </>
      ) : (
        <Paragraph>No user selected.</Paragraph>
      )}
    </section>
  );
};

export default RemoveGitlabUser;

import React, { useReducer } from "react";
import { merge } from "lodash-es";

import {
  RemoveUserPageState,
  Action,
  RemoveUserActions,
  RecursivePartial,
} from "../interfaces";

import ServiceStepper from "../components/organisms/ServiceStepper";
import RemoveJiraUser from "../components/organisms/RemoveJiraUser";
import RemoveGoogleUser from "../components/organisms/RemoveGoogleUser";
import RemoveGitlabUser from "../components/organisms/RemoveGitlabUser";
import RemoveBasecampUser from "../components/organisms/RemoveBasecampUser";
import RemoveUserOtherServices from "../components/organisms/RemoveUserOtherServices";

function getInitialPageState(): RemoveUserPageState {
  return {
    userToRemove: {
      fullName: "",
      googleId: "",
      email: "",
    },
    google: {
      authorized: true,
      userRemoved: false,
    },
    jira: {
      authorized: true,
      userRemoved: false,
    },
    gitlab: {
      authorized: false,
      userRemoved: false,
    },
    basecamp: {
      authorized: false,
      userRemoved: false,
    },
  };
}

function getNewState(action: Action): RecursivePartial<RemoveUserPageState> {
  switch (action.type) {
    case RemoveUserActions.SET_USER_TO_REMOVE:
      return {
        userToRemove: action.payload,
        gitlab: { userRemoved: false },
        google: { userRemoved: false },
        jira: { userRemoved: false },
      };
    case RemoveUserActions.GITLAB_AUTHORIZED:
      return { gitlab: { authorized: true } };
    case RemoveUserActions.GITLAB_USER_BLOCKED:
      return { gitlab: { userRemoved: true } };
    case RemoveUserActions.GOOGLE_USER_REMOVED:
      return { google: { userRemoved: true } };
    case RemoveUserActions.JIRA_USER_DISABLED:
      return { jira: { userRemoved: true } };
    case RemoveUserActions.BASECAMP_AUTHORIZED:
      return { basecamp: { authorized: true } };
    case RemoveUserActions.BASECAMP_USER_REMOVED:
      return { basecamp: { userRemoved: true } };
    default:
      return {};
  }
}

function reducer(
  state: RemoveUserPageState,
  action: Action
): RemoveUserPageState {
  if (action.type == RemoveUserActions.RESET_USER) {
    const resetState = getInitialPageState();
    resetState.gitlab.authorized = state.gitlab.authorized;
    return resetState;
  }
  const newState = getNewState(action);
  const updatedState = merge(state, newState);
  return { ...updatedState };
}

export default function RemoveUserPage(): JSX.Element {
  const [pageState, dispatch] = useReducer(reducer, getInitialPageState());
  const steps = ["Google", "Jira/Confluence", "Gitlab", "Basecamp", "Other Services"];
  const props = { userData: pageState.userToRemove, dispatch: dispatch };
  const stepContents = [
    <RemoveGoogleUser key="google" stepState={pageState.google} {...props} />,
    <RemoveJiraUser key="jira" stepState={pageState.jira} {...props} />,
    <RemoveGitlabUser key="gitlab" stepState={pageState.gitlab} {...props} />,
    <RemoveBasecampUser key="basecamp" stepState={pageState.basecamp} {...props}/>,
    <RemoveUserOtherServices key="other" />,
  ];
  return (
    <ServiceStepper
      steps={steps}
      stepContents={stepContents}
      onReset={() => dispatch({ type: RemoveUserActions.RESET_USER })}
    />
  );
}

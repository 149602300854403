import React from "react";
import { Button } from "@material-ui/core";

interface NextButtonProps {
  onClick: () => void;
  isLastStep: boolean;
}

const NextButton: React.FunctionComponent<NextButtonProps> = ({
  onClick,
  isLastStep,
}: NextButtonProps) => {
  const buttonText = isLastStep ? "Finish" : "Next";
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      {buttonText}
    </Button>
  );
};

export default NextButton;

import React, { useState, useEffect } from "react";

import { SiteActions } from "../interfaces";

import CreateUserPage from "./CreateUserPage";
import RemoveUserPage from "./RemoveUserPage";
import ActionSelectorPage from "./ActionSelectorPage";
import LoginPage from "./LoginPage";
import { loginUrl, getMe } from "../requests";
import Header from "../components/molecules/Header";

export default function HomePage(): JSX.Element {
  const [selectedAction, setSelectedAction] = useState<
    SiteActions | undefined
  >();
  let page;
  switch (selectedAction) {
    case SiteActions.ADD_USER:
      page = <CreateUserPage />;
      break;
    case SiteActions.REMOVE_USER:
      page = <RemoveUserPage />;
      break;
    default:
      page = (
        <ActionSelectorPage
          setAddUser={() => setSelectedAction(SiteActions.ADD_USER)}
          setRemoveUser={() => setSelectedAction(SiteActions.REMOVE_USER)}
        />
      );
  }

  const [currentUserEmail, setCurrentUserEmail] = useState<string | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  useEffect(() => {
    async function getUserEmail() {
      try {
        setLoadingUser(true);
        const meResp = await getMe();
        setCurrentUserEmail(meResp.data["email"]);
      } catch {
        setCurrentUserEmail(null);
      }
      setLoadingUser(false);
    }
    getUserEmail();
  }, []);
  const handleLogin = () => {
    window.location.href = loginUrl;
  };
  return currentUserEmail ? (
    <>
      <Header
        currentUserEmail={currentUserEmail}
        selectedAction={selectedAction}
        onSelectAction={setSelectedAction}
      />
      {page}
    </>
  ) : (
    <LoginPage isLoading={loadingUser} onLogin={handleLogin} />
  );
}

import React from "react";
import { Icon, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  homeContainer: {
    height: "100%",
  },
  actionButton: {
    width: "calc(100% - 80px)",
    margin: "40px",
    padding: "10px 40px",
    boxSizing: "border-box",
  },
});

interface ActionSelectorProps {
  setAddUser: () => void;
  setRemoveUser: () => void;
}

export default function ActionSelector({
  setAddUser,
  setRemoveUser,
}: ActionSelectorProps): JSX.Element {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.homeContainer}
    >
      <Grid item>
        <Paper>
          <Grid container direction="column" alignItems="stretch">
            <Grid item>
              <Button
                size="large"
                variant="contained"
                color="primary"
                startIcon={<Icon>person_add</Icon>}
                className={classes.actionButton}
                onClick={setAddUser}
              >
                Add User
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Icon>person_remove</Icon>}
                className={classes.actionButton}
                onClick={setRemoveUser}
              >
                Remove User
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

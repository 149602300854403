import React from "react";

import {
  UserToRemove,
  Action,
  RemoveUserActions,
  RemoveUserStepState,
} from "../../interfaces";

import FormGroup from "../atoms/FormGroup";
import StepSection from "../atoms/StepSection";
import SectionHeader from "../atoms/SectionHeader";
import GoogleUserSelect from "../molecules/google/UserSelect";
import GoogleRemoveUser from "../molecules/google/GoogleRemoveUser";
import { GoogleAdminSiteLink } from "../molecules/AdminSiteLink";

interface RemoveGoogleUserProps {
  userData: UserToRemove;
  dispatch: React.Dispatch<Action>;
  stepState: RemoveUserStepState;
}

const RemoveGoogleUser: React.FC<RemoveGoogleUserProps> = ({
  userData,
  dispatch,
  stepState,
}: RemoveGoogleUserProps) => {
  return (
    <StepSection>
      <GoogleAdminSiteLink />
      <SectionHeader>Select User To Remove</SectionHeader>
      <FormGroup>
        <GoogleUserSelect
          userToRemove={userData}
          setUserToRemove={(user) =>
            dispatch({
              type: RemoveUserActions.SET_USER_TO_REMOVE,
              payload: user,
            })
          }
        />
      </FormGroup>
      {userData.email && (
        <GoogleRemoveUser
          userData={userData}
          userRemoved={stepState.userRemoved}
          onRemove={() =>
            dispatch({ type: RemoveUserActions.GOOGLE_USER_REMOVED })
          }
        />
      )}
    </StepSection>
  );
};

export default RemoveGoogleUser;

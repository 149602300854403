import React from "react";

import {
  UserData,
  CreateUserActions,
  Action,
  CreateUserStepState,
} from "../../interfaces";
import { createGitlabUser } from "../../requests";
import useRequest from "../../hooks";

import HeaderWithIndicator from "../molecules/HeaderWithIndicator";
import GitlabAuthorization from "../molecules/gitlab/GitlabAuthorize";
import GitlabUserData from "../molecules/gitlab/GitlabUserData";
import LoaderButton from "../atoms/buttons/LoaderButton";
import StepSection from "../atoms/StepSection";
import LinkToCreatedAccount from "../atoms/LinkToCreatedAccount";
import RequestError from "../atoms/errors/RequestError";
import { GitlabAdminSiteLink } from "../molecules/AdminSiteLink";

interface GitlabProps {
  userData: UserData;
  dispatch: React.Dispatch<Action>;
  stepState: CreateUserStepState;
}

const Gitlab: React.FunctionComponent<GitlabProps> = ({
  userData,
  dispatch,
  stepState,
}: GitlabProps) => {
  const { requestStatus, makeRequest } = useRequest(
    createGitlabUser,
    userData,
    (response) =>
      dispatch({
        type: CreateUserActions.GITLAB_USER_CREATED,
        payload: { link: response.data.link },
      })
  );
  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    makeRequest();
    event.preventDefault();
  };
  const handleAuthorize = () =>
    dispatch({ type: CreateUserActions.GITLAB_AUTHORIZED });
  return (
    <section>
      <GitlabAdminSiteLink />
      <GitlabAuthorization
        isAuthorized={stepState.authorized}
        onAuthorize={handleAuthorize}
      />
      <StepSection>
        <HeaderWithIndicator isSuccess={stepState.userCreated}>
          Create User
        </HeaderWithIndicator>
        <GitlabUserData userData={userData} />
        {requestStatus.error && (
          <RequestError errorText={requestStatus.error} />
        )}
        {stepState.link && <LinkToCreatedAccount link={stepState.link} />}
        <LoaderButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          isLoading={requestStatus.loading}
        >
          Create User
        </LoaderButton>
      </StepSection>
      {/*<h3>Create SSH Key?</h3>*/}
    </section>
  );
};

export default Gitlab;

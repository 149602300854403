import React, { useState, useEffect } from "react";
import { CircularProgress, Link } from "@material-ui/core";
import { AxiosResponse } from "axios";

import StepSection from "../atoms/StepSection";
import LoaderButton from "../atoms/buttons/LoaderButton";
import HeaderWithIndicator from "./HeaderWithIndicator";
import Paragraph from "../atoms/Paragraph";

interface CheckUserRemovedProps {
  userEmail: string;
  userDisabled: boolean;
  onDisabled: () => void;
  getUserFunc: (email: string) => Promise<AxiosResponse>;
  errorComponent: JSX.Element;
}

const CheckUserRemoved: React.FC<CheckUserRemovedProps> = ({
  userEmail,
  userDisabled,
  onDisabled,
  getUserFunc,
  errorComponent,
}: CheckUserRemovedProps) => {
  const [userData, setUserData] = useState<any>();
  const [userDataLoading, setUserDataLoading] = useState<boolean>(true);
  const [userCheckCount, setUserCheckCount] = useState<number>(0);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    // force component to reload and make request for user again
    setUserCheckCount(userCheckCount + 1);
  };
  useEffect(() => {
    async function setUserDataFromAPI() {
      if (!userEmail) return;
      setUserDataLoading(true);
      try {
        const resp = await getUserFunc(userEmail);
        setUserData(resp.data);
      } catch (error) {
        if (error.isAxiosError && error.response.status === 404) {
          onDisabled();
        } else {
          console.log(error);
          setErrorOccurred(true);
        }
      }
      setUserDataLoading(false);
    }
    setUserDataFromAPI();
  }, [userEmail, userDisabled, userCheckCount]);
  const checkButton = (
    <LoaderButton
      variant="contained"
      color="secondary"
      onClick={handleSubmit}
      disabled={userDisabled}
      isLoading={userDataLoading}
    >
      Check If User Is Disabled
    </LoaderButton>
  );
  let content;
  if (userDataLoading) {
    content = <CircularProgress />;
  } else if (userDisabled) {
    content = (
      <>
        <Paragraph>The user is already disabled or deleted.</Paragraph>
        {checkButton}
      </>
    );
  } else if (errorOccurred) {
    content = errorComponent;
  } else if (userData) {
    content = (
      <>
        <Paragraph>
          To disable user follow the link:{" "}
          <Link href={userData.link || userData.account_link} target="_blank">
            {userData.displayName || userData.name}
          </Link>
          . The change might take several seconds.
        </Paragraph>
        {checkButton}
      </>
    );
  } else {
    // not sure what this scenario is
    content = errorComponent;
  }

  return (
    <StepSection>
      <HeaderWithIndicator isSuccess={userDisabled}>
        Disable User
      </HeaderWithIndicator>
      {content}
    </StepSection>
  );
};

export default CheckUserRemoved;

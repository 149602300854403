import React from "react";

import {
  UserData,
  Action,
  CreateUserActions,
  GoogleGroupsOptions,
} from "../../interfaces";
import useRequest from "../../hooks";
import { addUserToGoogleGroups } from "../../requests";

import FormGroup from "../atoms/FormGroup";
import Paragraph from "../atoms/Paragraph";
import GoogleGroupSelect from "../molecules/google/GroupSelect";
import LoaderButton from "../atoms/buttons/LoaderButton";

interface GoogleGroupsInputProps {
  userData: UserData;
  onChange: (event: React.ChangeEvent<any>) => void;
  dispatch: React.Dispatch<Action>;
  groupsOptions: GoogleGroupsOptions;
}

const GoogleGroupsInput: React.FunctionComponent<GoogleGroupsInputProps> = ({
  userData,
  onChange,
  groupsOptions,
  dispatch,
}: GoogleGroupsInputProps) => {
  const { requestStatus, makeRequest } = useRequest(
    addUserToGoogleGroups,
    { email: userData.primaryEmail, ...groupsOptions },
    () => dispatch({ type: CreateUserActions.GOOGLE_USER_ADDED_TO_GROUPS })
  );
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    makeRequest();
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <GoogleGroupSelect
          onChange={onChange}
          value={groupsOptions.addToOfficeGroup}
        />
      </FormGroup>
      {requestStatus.error && (
        <Paragraph>
          An error occurred:{" "}
          <span style={{ color: "red" }}>{requestStatus.error}</span>
        </Paragraph>
      )}
      <LoaderButton
        type="submit"
        variant="contained"
        color="secondary"
        isLoading={requestStatus.loading}
      >
        Submit
      </LoaderButton>
    </form>
  );
};

export default GoogleGroupsInput;

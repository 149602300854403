import React, { useCallback, useEffect } from "react";
import { Button } from "@material-ui/core";

import { basecampLoginUrl } from "../../../requests";

import StepSection from "../../atoms/StepSection";
import HeaderWithIndicator from "../HeaderWithIndicator";
import Paragraph from "../../atoms/Paragraph";

interface BasecampAuthorizationProps {
  isAuthorized: boolean;
  onAuthorize: () => void;
}

// TODO create one component for Basecamp and Gitlab
const AuthorizeText: React.FC = () => {
  const openPopup = () => {
    window.open(basecampLoginUrl, "", "width=1000,height=700");
  };
  return (
    <>
      <Paragraph>You need to authorize to manage users in Basecamp</Paragraph>
      <Button variant="contained" color="secondary" onClick={openPopup}>
        Authorize
      </Button>
    </>
  );
};

const AuthorizedText: React.FC = () => {
  return <Paragraph>You&apos;ve successfully authorized</Paragraph>;
};

const BasecampAuthorization: React.FC<BasecampAuthorizationProps> = ({
  isAuthorized,
  onAuthorize,
}: BasecampAuthorizationProps) => {
  const handleMessage = useCallback((event) => {
    if (event.data == "basecamp_authorized") {
      onAuthorize();
    }
  }, []);
  useEffect(() => {
    if (!isAuthorized) {
      window.addEventListener("message", handleMessage);
    }
    return () => window.removeEventListener("message", handleMessage);
  }, []);
  return (
    <StepSection>
      <HeaderWithIndicator isSuccess={isAuthorized}>
        Authorize
      </HeaderWithIndicator>
      {isAuthorized ? <AuthorizedText /> : <AuthorizeText />}
    </StepSection>
  );
};

export default BasecampAuthorization;

import React from "react";
import { Link } from "@material-ui/core";

import Paragraph from "./Paragraph";

interface LinkToCreatedAccountProps {
  link: string;
}

const LinkToCreatedAccount: React.FC<LinkToCreatedAccountProps> = ({
  link
}: LinkToCreatedAccountProps) => {
  return (
    <Paragraph>
      An{" "}
      <Link href={link} target="_blank">
        account
      </Link>{" "}
      was successfully created.
    </Paragraph>
  );
};

export default LinkToCreatedAccount;

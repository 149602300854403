import React from "react";

import SuccessIndicator from "../atoms/SuccessIndicator";
import SectionHeader from "../atoms/SectionHeader";

interface HeaderWithIndicatorProps {
  isSuccess: boolean;
  children: React.ReactNode;
}

const HeaderWithIndicator: React.FunctionComponent<HeaderWithIndicatorProps> = ({
  isSuccess,
  children,
}: HeaderWithIndicatorProps) => {
  return (
    <SectionHeader>
      {children}
      {isSuccess ? <SuccessIndicator /> : null}
    </SectionHeader>
  );
};

export default HeaderWithIndicator;

import React from "react";
import { Grid, TextField } from "@material-ui/core";

import FormControl from "../../atoms/FormGroup";

interface UserNameInputProps {
  firstName?: string;
  surname?: string;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const UserNameInput: React.FunctionComponent<UserNameInputProps> = ({
  firstName,
  surname,
  onChange,
}: UserNameInputProps) => {
  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item>
        <FormControl>
          <TextField
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={onChange}
          ></TextField>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <TextField
            label="Surname"
            type="text"
            name="surname"
            value={surname}
            onChange={onChange}
          ></TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default UserNameInput;
